import React from "react";
import css from "./triangle.module.css"
import Piece from "../piece/piece"
import { Player } from "../board/board";

interface TriangleContainerProps {
  topHalf: boolean
  playerOnePieces: number
  playerTwoPieces: number
  position: number
  showPotentialMoves: (position: number) => void
  isHighlighted: boolean
  isSelected: boolean[]
  setIsSelected:  (newVal: boolean[]) => void
  playerTurn: Player
  isClickable: boolean
  movePiece: (position: number) => void
}

const TriangleContainer: React.FC<TriangleContainerProps> = ({ movePiece, isClickable, topHalf, playerOnePieces, playerTwoPieces, position, showPotentialMoves, isHighlighted, isSelected, setIsSelected, playerTurn}: TriangleContainerProps) => {

  const toggleSelected = () => setIsSelected(Array.from({ length: 24 }, (_, index) => index===position && !isSelected[position] ? true : false));
  return (
    <div
      className={css.triangleContainer}
      style={{
        flexDirection: topHalf ? 'column' : 'column-reverse',
        backgroundColor: isHighlighted ? '#00ff00' : '#ffffff',
      }}
    >
      <div
        className={css.triangle}
        style={{
          ...(topHalf
            ? { borderTop: '300px solid #ff0000' }
            : { borderBottom: '300px solid #ff0000' }),
          pointerEvents: isClickable ? 'auto' : 'none',
          cursor: isClickable ? 'pointer' : 'default',
        }}
        onClick={() => {
          if(isHighlighted){
            movePiece(position)
          }
          else {
            isSelected[position]? showPotentialMoves(-2): showPotentialMoves(position)
            toggleSelected()
          }
        }}
      />
      {Array.from({ length: playerOnePieces }, (_, index) => (
        <Piece position={position} color='lightblue'/>
      ))}
      {Array.from({ length: playerTwoPieces }, (_, index) => (
        <Piece position={position} color='darkblue'/>
      ))}
    </div>
  );
}

export default TriangleContainer