import React, { useState } from 'react'
import css from "./board.module.css";
import TriangleContainer from "../triangle/triangle"
import Piece from '../piece/piece';

import dice1Img from '../dice/dice1.png';
import dice2Img from '../dice/dice2.png';
import dice3Img from '../dice/dice3.png';
import dice4Img from '../dice/dice4.png';
import dice5Img from '../dice/dice5.png';
import dice6Img from '../dice/dice6.png';
import diceAny from '../dice/diceAny.png';

interface BoardProps {
}

export enum Player {
  One,
  Two
}

export enum CurrentStep {
  Roll,
  Move
}

const Board: React.FC<BoardProps> = () => {

  const [playerTurn, setPlayerTurn] = useState(Player.One)

  const [playerOnePieces, setPlayerOnePieces] = useState([2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 0, 0, 0, 0, 3, 0, 5, 0, 0, 0, 0, 0]);
  const [playerTwoPieces, setPlayerTwoPieces] = useState([0, 0, 0, 0, 0, 5, 0, 3, 0, 0, 0, 0, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2]);


  const [playerOneBar, setPlayerOneBar] = useState<number>(0);
  const [playerTwoBar, setPlayerTwoBar] = useState<number>(0);

  const [currentRoll, setCurrentRoll] = useState<number[]>([0, 0]);
  const [movesLeft, setMovesLeft] = useState<number[]>([]);

  const [potentialMoves, setPotentialMoves] = useState<boolean[]>(Array.from({ length: 24 }, () => false));
  const [isSelected, setIsSelected] = useState<boolean[]>(Array.from({ length: 24 }, () => false));

  const currentStep = currentRoll[0] === 0 ? CurrentStep.Roll : CurrentStep.Move;

  const togglePlayerTurn = () => {
    setPlayerTurn(playerTurn === Player.One ? Player.Two : Player.One);
  }

  const resetDice = () => {
    setCurrentRoll([0, 0]);
  }

  const rollDice = () => {
    const dice1 = Math.floor(Math.random() * 6) + 1;
    const dice2 = Math.floor(Math.random() * 6) + 1;
    setCurrentRoll([dice1, dice2]);
    if (dice1 === dice2) {
      setMovesLeft([dice1, dice1, dice1, dice1]);
    }
    else {
      setMovesLeft([dice1, dice2]);
    }
  };

  const getDiceImage = (diceValue: number) => {
    switch (diceValue) {
      case 0: return diceAny;
      case 1: return dice1Img;
      case 2: return dice2Img;
      case 3: return dice3Img;
      case 4: return dice4Img;
      case 5: return dice5Img;
      case 6: return dice6Img;
      default: return dice1Img;
    }
  };

  const showPotentialMoves = (position: number) => {
    if (position === -2 || currentStep === CurrentStep.Roll) {
      setPotentialMoves(Array.from({ length: 24 }, () => false));
      return;
    }


    let displacements;
    if (movesLeft.every(item => item === movesLeft[0])) {
      displacements = movesLeft.map((move, index) => move * (index + 1));
    }
    else {
      displacements = [...movesLeft, movesLeft.reduce((a, b) => a + b, 0)];
    }

    let moves = playerTurn === Player.One ? displacements.map(displacement => position + displacement) : displacements.map(displacement => position - displacement);
    moves = moves.filter(move => move >= 0 && move <= 23);
    for (const move of moves) {
      if (playerTurn === Player.One && playerTwoPieces[move] > 1) {
        moves = moves.filter(m => m !== move);
      }
      if (playerTurn === Player.Two && playerOnePieces[move] > 1) {
        moves = moves.filter(m => m !== move);
      }
    }
    const newPotentialMoves = Array.from({ length: 24 }, (_, index) => moves.includes(index) ? true : false);
    setPotentialMoves(newPotentialMoves);
  }

  const movePiece = (endPosition: number) => {
    const startPosition = isSelected.indexOf(true);
    const newPieces = playerTurn === Player.One ? [...playerOnePieces] : [...playerTwoPieces];
    newPieces[startPosition]--;
    newPieces[endPosition]++;
    playerTurn === Player.One ? setPlayerOnePieces(newPieces) : setPlayerTwoPieces(newPieces);

    if (playerTurn === Player.One && playerTwoPieces[endPosition] === 1) {
      setPlayerTwoBar(playerTwoBar + 1);
      const newPieces = [...playerTwoPieces];
      newPieces[endPosition]--;
      setPlayerTwoPieces(newPieces);
    }
    if (playerTurn === Player.Two && playerOnePieces[endPosition] === 1) {
      setPlayerOneBar(playerOneBar + 1);
      const newPieces = [...playerOnePieces];
      newPieces[endPosition]--;
      setPlayerOnePieces(newPieces);
    }

    setIsSelected(Array.from({ length: 24 }, () => false));
    setPotentialMoves(Array.from({ length: 24 }, () => false));

    const spacesMoved = Math.abs(startPosition - endPosition)

    let movesLeftTemp = [...movesLeft]
    const index = movesLeft.indexOf(spacesMoved);
    if (index === -1) {
      if (movesLeft.length === 2) {
        movesLeftTemp = []
      }
      else if (movesLeft.length === 3) {
        movesLeftTemp = spacesMoved === movesLeft[0] * 2 ? [movesLeft[0]] : []
      }
      else if (movesLeft.length === 4) {
        movesLeftTemp = spacesMoved === movesLeft[0] * 2 ? [movesLeft[0], movesLeft[0]] : spacesMoved === movesLeft[0] * 3 ? [movesLeft[0]] : []
      }
    }
    else {
      movesLeftTemp.splice(index, 1)
    }

    if (movesLeftTemp.length === 0) {
      togglePlayerTurn();
      resetDice()
    }
    else {
      setMovesLeft(movesLeftTemp)
    }
  }

  const renderBoardQuarter = (positions: number[], topHalf: boolean): any => {
    return positions.map((position) => {
      const isClickable =
        // not clickable if player has pieces on the bar
        !(playerTurn === Player.One && playerOneBar > 0) && !(playerTurn === Player.Two && playerTwoBar > 0)
        &&
        (
          currentStep === CurrentStep.Move &&
          (
            (playerTurn === Player.One && playerOnePieces[position] > 0) ||
            (playerTurn === Player.Two && playerTwoPieces[position] > 0)
          )
        )
        ||
        potentialMoves[position];


      return (
        <TriangleContainer
          key={position}
          isClickable={isClickable}
          playerTurn={playerTurn}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          isHighlighted={potentialMoves[position]}
          showPotentialMoves={showPotentialMoves}
          playerOnePieces={playerOnePieces[position]}
          playerTwoPieces={playerTwoPieces[position]}
          position={position}
          topHalf={topHalf}
          movePiece={movePiece}
        />
      );
    });
  };

  const isBarClickable = currentStep === CurrentStep.Move && (playerTurn === Player.One && playerOneBar > 0) || (playerTurn === Player.Two && playerTwoBar > 0)


  return (
    <div className={css.board}>
      {/* Dice Container */}
      <div className={css.diceContainer} onClick={rollDice} style={{ pointerEvents: currentStep === CurrentStep.Roll ? 'auto' : 'none' }}>
        <img className={css.dice} src={getDiceImage(currentRoll[0])} />
        <img className={css.dice} src={getDiceImage(currentRoll[1])} />
      </div>

      {/* Top Half of the Board */}
      <div className={css.boardHalf}>
        <div className={css.boardQuarter}>
          {renderBoardQuarter([0, 1, 2, 3, 4, 5], true)}
        </div>
        <div className={css.boardQuarter}>
          {renderBoardQuarter([23, 22, 21, 20, 19, 18], false)}
        </div>
      </div>

      {/* Center Bar */}
      <div className={css.centre}>
        <div style={{ height: "40px" }} />
        <div
          className={css.bar}
          style={{
            pointerEvents: isBarClickable ? 'auto' : 'none',
            cursor: isBarClickable ? 'pointer' : 'default'
          }}
          onClick={() => {
            playerTurn === Player.One? showPotentialMoves(-1): showPotentialMoves(24)
          }
          }
        >
          {Array.from({ length: playerOneBar }, (_, index) => <Piece key={index} position={-1} color='lightblue' />)}
          {Array.from({ length: playerTwoBar }, (_, index) => <Piece key={index} position={-1} color='darkblue' />)}
        </div>
      </div>

      {/* Bottom Half of the Board */}
      <div className={css.boardHalf}>
        <div className={css.boardQuarter}>
          {renderBoardQuarter([6, 7, 8, 9, 10, 11], true)}
        </div>
        <div className={css.boardQuarter}>
          {renderBoardQuarter([17, 16, 15, 14, 13, 12], false)}
        </div>
      </div>
    </div>
  );
};

export default Board;