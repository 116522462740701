import React from 'react'
import css from "./piece.module.css";

interface PieceProps {
  color: string
  position: number
}

const Piece: React.FC<PieceProps> = ({color, position}) => {

  return (
    <div
    className={css.piece}
    style={{backgroundColor: color}}
    >
    </div>
  );
};

export default Piece;