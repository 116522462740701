import css from "./App.module.css";
import Board from './components/board/board'

function App() {
  return (
    <div className={css.container}>
      <div className={css.title}>
        Bkgammon
      </div>
      <Board/>
    </div>
  );
}

export default App;